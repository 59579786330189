import React, { PropsWithChildren, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Center, Spinner } from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { currentApplicationId } from "@shared/store";
import { ApplicationProvider } from "@shared/ui/components/application-context";
import { useAtom } from "jotai";

export interface ApplicationContainerProps {
  applicationId?: number;
}

export const ApplicationContainer = ({
  applicationId,
  children,
}: PropsWithChildren<ApplicationContainerProps>) => {
  const { isLoading: isLoadingAuth } = useAuth0();

  const [id, setApplicationId] = useAtom(currentApplicationId);

  const { data, isLoading, isError, error } = useGetApplication({
    id: applicationId || id,
  });

  useEffect(() => {
    applicationId && setApplicationId(applicationId);
  }, [applicationId, setApplicationId]);

  if ((applicationId && isLoading) || isLoadingAuth) {
    return (
      <Center style={{ minHeight: "100vh" }}>
        <Spinner />
      </Center>
    );
  }

  if (isError) {
    // send that to be catch by the error boundary
    // will be logged in datadog
    throw error;
  }

  return (
    <ApplicationProvider application={data}>{children}</ApplicationProvider>
  );
};
