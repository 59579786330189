import { useEffect } from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useGetAccount } from "@shared/api/hooks/account";
import { HomePage, Header } from "@shared/ui";
import { useApplicationContext } from "@shared/ui/components/application-context";
import { commonServerProps } from "@shared/utils/common-server-props";

import { withApplication } from "@lib/with-application";

import type { NextPageWithLayout } from "@pages/_app";
import type { GetServerSideProps } from "next";
const Home = () => {
  const { setApplication } = useApplicationContext();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setApplication({}), []);
  const { data: account } = useGetAccount();

  return (
    <HomePage
      getApplicationUrl={(application) => {
        return `/applications/${application.id}/applicant/${account?.id}`;
      }}
    />
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return commonServerProps({
    context,
    namespaces: ["common", "applications", "form"],
  });
};

// const Page: NextPageWithLayout = withAuthenticationRequired(
//   withPermissions(["list:documents?????"], Home)
// );

const Page: NextPageWithLayout<never> = withAuthenticationRequired(
  withApplication(Home)
);

Page.getLayout = (page) => (
  <main className="bg-default">
    <Header />
    {page}
  </main>
);

export default Page;
